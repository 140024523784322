import React from 'react'
import { Link } from 'gatsby'

export const MenuList = () => {
  return (
    <>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-183">
        <Link to="/" activeClassName="active" data-testid="home-link">
          Home
        </Link>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page page_item page-item-209 menu-item-251">
        <Link to="/contact/" activeClassName="active" data-testid="contact-link">
          Contact
        </Link>
      </li>
    </>
  )
}

export default MenuList